import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import ClbLogo from '../Images/ClbGroupLogo.png';
import { LanguageSelector } from './LanguageSelector';
import LogoutIcon from '@mui/icons-material/Logout';

interface Props {
    description: string;
}

export function Header(props: Props) {
    let environmentLabel = process.env.REACT_APP_ENV_NAME;

    const auth = useAuth();

    return (
        <div className="background-color-blue-radial">
            {getContent(auth.isAuthenticated)}
        </div>
    );

    function getContent(isAuthenticated: boolean) {
        return (
            <div className="d-flex">
                <div className="me-auto p-2">
                    {GetDescriptionOrLogo()}
                </div>
                {GetEnvironment()}
                <div className="p-2">
                    {isAuthenticated ? <><Link title="Afmelden" to="/logout"><LogoutIcon fontSize="large" sx={{ color: "white" }} /></Link></> : <></>}
                </div>
                <div className="p-2">
                    <LanguageSelector useshortCodes={true} />
                </div>
            </div>
        );
    }

    function GetDescriptionOrLogo() {
        if (props.description !== '') {
            return (
                <div style={{ fontSize: "25px" }} className='ms-3'>
                    {props.description}
                </div>
            )
        } else {
            return (
                <img src={ClbLogo} alt="CLB Group" style={{ width: "6em", height: "2.3em" }} className='ms-3' />
            )
        }
    }

    function GetEnvironment() {
        if (environmentLabel !== "" && environmentLabel !== undefined)
            return (
                <div className="p-1 flex-fill">
                    <b>
                        {environmentLabel}
                    </b>
                </div>
            )
    }
}
