import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Header } from "../../Components/Header";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import SurveyService from "../../Services/SurveyService";
import SurveyTypeModel from "../../Models/Survey/SurveyTypeModel";
import EDPBLogo from "../../Images/ClbEDPBLogo.png";
import GeneralConditions from "./GeneralConditions";
import SurveyModel from "../../Models/Survey/SurveyModel";
import SetGeneralConditionsModel from "../../Models/Survey/GeneralConditions/SetGeneralConditionsModel";
import SurveyDetail from "./SurveyDetail";
import LoadingScreen from "../LoadingScreen";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../../Components/LanguageSelector";

export default function SurveyOverview() {
  const { t, i18n } = useTranslation("survey");

  const navigate = useNavigate();
  const location = useLocation();
  const states = location.state as any;

  const planningItemDetailId = (states.planningItemDetailId ?? null) as
    | string
    | null;
  const accountId = (states.accountId ?? null) as string | null;

  const surveyService = useMemo(() => new SurveyService(), []);

  const [loading, setLoading] = useState<boolean>(false);
  const [survey, setSurvey] = useState<SurveyModel>();
  const [surveyType, setSurveyType] = useState<SurveyTypeModel>(
    states.surveyType as SurveyTypeModel
  );

  useEffect(() => {
    createOrLoadSurvey();
  }, []);

  useEffect(() => {
    loadSurveyType();
  }, [i18n.language]);

  function loadSurveyType() {
    setLoading(true);

    surveyService
      .getSurveyType(
        surveyType.surveyTypeId,
        planningItemDetailId,
        null,
        null,
        i18n.language
      )
      .then((result) => {
        if (result === null) {
          toast.error(t("Vragenlijst type niet gevonden."));
        } else {
          setSurveyType(result);
        }
      })
      .catch((error) => {
        toast.error(t("Fout bij ophalen van vragenlijst type."));
      })
      .finally(() => {});

    setLoading(false);
  }

  return (
    <>
      {/* <Header description={t("Vragenlijst")} /> */}
      <Box p={1} sx={{ height: "100%", width: "100%" }}>
        {surveyHeader()}
        {getFormFields()}
      </Box>
    </>
  );

  function getFormFields() {
    if (loading) {
      return loadingScreen();
    }

    return (
      <>
        {generalConditions()}
        {surveyBody()}
      </>
    );
  }

  function surveyHeader() {
    return (
      <>
        <div className="position-relative">
          <div className="position-absolute top-0 start-0">
            <img
              src={EDPBLogo}
              alt="CLB EDPB"
              className="ms-1"
              style={{ height: 40 }}
            />
          </div>
          <div className="p-2 position-absolute top-0 end-0">
            <LanguageSelector useshortCodes={true} />
          </div>
        </div>
        <h2
          className="float-center"
          style={{ borderBottom: "1px solid #212529", paddingBottom: "1rem" }}
        >
          {surveyType.name}
        </h2>{" "}
      </>
    );
  }

  function generalConditions() {
    if (survey !== undefined) {
      if (survey?.generalConditionsAccepted !== true) {
        return (
          <GeneralConditions
            surveyTypeId={surveyType.surveyTypeId}
            surveyId={survey.id}
            callBack={generalConditionsCallBack}
          />
        );
      }
    }
  }

  function surveyBody() {
    if (survey !== undefined) {
      if (survey?.generalConditionsAccepted === true) {
        if (survey?.completed === true) {
          toast.warning(t("Deze vragenlijst is reeds ingevuld."));
        } else {
          return (
            <SurveyDetail
              survey={survey}
              surveyCompleted={surveyCompleted}
              surveyTypeId={surveyType.surveyTypeId}
            />
          );
        }
      }
    }
  }

  function surveyCompleted() {
    if (planningItemDetailId !== null) {
      const state = {
        state: {
          planningItemDetailId: planningItemDetailId,
        },
      };

      navigate("/SurveySelect", state);
    } else {
      navigate(`/anonymous?t=${surveyType.surveyTypeId}&a=${accountId}`);
    }
  }

  function generalConditionsCallBack(result: SetGeneralConditionsModel) {
    if (result.accepted === false) {
      if (planningItemDetailId !== null) {
        const state = {
          state: {
            planningItemDetailId: planningItemDetailId,
          },
        };

        navigate("/SurveySelect", state);
      } else {
        navigate(`/anonymous?t=${surveyType.surveyTypeId}&a=${accountId}`);
      }
    } else {
      //reload survey
      loadSurvey(result.surveyId);
    }
  }

  function loadingScreen() {
    return <LoadingScreen />;
  }

  function createOrLoadSurvey() {
    setLoading(true);

    surveyService
      .createOrLoadSurvey({
        surveyTypeId: surveyType.surveyTypeId,
        surveyId: surveyType.surveyId,
        accountId: accountId,
        planningItemDetailId: planningItemDetailId,
      })
      .then((result) => {
        if (result) {
          localStorage.setItem("surveyid", result);

          loadSurvey(result);
        } else {
          //niks terug
          toast.error(t("Fout bij aanmaken van Vragenlijst."));
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {});
    setLoading(false);
  }

  function loadSurvey(surveyId: string) {
    setLoading(true);
    if (surveyId) {
      surveyService
        .getSurvey(surveyId)
        .then((result) => {
          if (result) {
            //survey geladen
            setSurvey(result);
          } else {
            //niks terug
            toast.error(t("Fout bij laden van Vragenlijst."));
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {});
    }
    setLoading(false);
  }
}
