
import ApiCalls from './ApiCalls';
import AuthenticationCodeModel from "../Models/Authentication/AuthenticationCodeModel";
import AuthenticationBirthdayModel from '../Models/Authentication/AuthenticationBirthdayModel';
import SendAuthenticationCodeModel from '../Models/Authentication/SendAuthenticationCodeModel';
import AuthenticationCodeResult from '../Models/Authentication/AuthenticationCodeResult';
import SendAuthenticationCodeResult from '../Models/Authentication/SendAuthenticationCodeResult';

export default class AuthenticationService {

    public async LoadAuthenticationCode(model: SendAuthenticationCodeModel): Promise<SendAuthenticationCodeResult> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/authentication/LoadAuthenticationCode/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async sendAuthenticationCodeBySMS(model: SendAuthenticationCodeModel): Promise<SendAuthenticationCodeResult> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/authentication/SendAuthenticationCodeBySMS/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async sendAuthenticationCodeByEmail(model: SendAuthenticationCodeModel): Promise<SendAuthenticationCodeResult> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/authentication/SendAuthenticationCodeByEmail/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async checkAuthenticationCode(model: AuthenticationCodeModel): Promise<AuthenticationCodeResult> {
        //console.log("checkAuthenticationCode");
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/authentication/CheckAuthenticationCode/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);

        return result;
    }

    public async confirmBirthDate(model: AuthenticationBirthdayModel): Promise<boolean> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/authentication/ConfirmBirthDate/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }
}