import SurveyModel from "../Models/Survey/SurveyModel";
import SurveyTypeResultModel from '../Models/Survey/SurveyTypeResultModel';
import ApiCalls from './ApiCalls';
import CreateSurveyModel from '../Models/Survey/CreateSurveyModel';
import QuestionModel from '../Models/Survey/Questions/QuestionModel';
import LoadQuestionModel from '../Models/Survey/Questions/LoadQuestionModel';
import SaveAnswerModel from '../Models/Survey/Questions/SaveAnswerModel';
import SaveAnswerResultModel from '../Models/Survey/Questions/SaveAnswerResultModel';
import FinishSurveyModel from "../Models/Survey/FinishSurveyModel";
import SurveyInfoModel from "../Models/Survey/SurveyInfoModel";
import SurveyTypeModel from "../Models/Survey/SurveyTypeModel";
import EndingTextModel from "../Models/Survey/EndingTextModel";

export default class SurveyService {

    public async getSurveyTypes(planningItemDetailId: string, language: string): Promise<SurveyTypeResultModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/survey/GetSurveyTypes/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, planningItemDetailId);
        return result;
    }

    public async getSurveyType(surveyTypeId: string, planningItemDetailId: string | null, surveyId: string | null, accountId: string | null, language: string): Promise<SurveyTypeModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/survey/GetSurveyType/${surveyTypeId}/${planningItemDetailId}/${surveyId}/${accountId}/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Get(url);
        return result;
    }

    public async getEndingText(surveyTypeId: string, language: string): Promise<EndingTextModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/survey/GetEndingText/${surveyTypeId}/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Get(url);
        return result;
    }

    public async createOrLoadSurvey(model: CreateSurveyModel): Promise<string> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/survey/CreateOrLoadSurvey/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async getSurvey(surveyId: string): Promise<SurveyModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/survey/GetSurvey/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, surveyId);
        return result;
    }

    public async loadQuestion(model: LoadQuestionModel, language: string): Promise<QuestionModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/survey/LoadQuestion/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async saveAnswer(model: SaveAnswerModel, language: string): Promise<SaveAnswerResultModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/survey/SaveAnswer/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async previousQuestion(model: LoadQuestionModel, language: string): Promise<QuestionModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/survey/PreviousQuestion/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async finishSurvey(surveyId: FinishSurveyModel): Promise<boolean> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/survey/FinishSurvey/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, surveyId);
        return result;
    }

    public async getInfo(surveyTypeId: string, language: string): Promise<SurveyInfoModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/survey/GetInfo/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, surveyTypeId);
        return result;
    }
}