import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthenticateCode from './Pages/Authentication/AuthenticateCode';
import AuthenticateBirthdate from './Pages/Authentication/AuthenticateBirthdate';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
import SurveySelect from './Pages/Survey/SurveySelect';
import SurveyOverview from './Pages/Survey/SurveyOverview';
import { useAuth } from './AuthContext';
import Anonymous from './Pages/Survey/Anonymous';

function App() {

    const { login, logout } = useAuth();

    return (
        <div className="app">
            <BrowserRouter>
                <div id="page-wrap" style={{ width: "100%", minHeight: "100vh", overflow: "hidden" }}>
                    <Routes>
                        {/* Default */}
                        <Route path='/login' Component={() => { login(); return null }} />
                        <Route path='/logout' Component={() => { logout(); return null }}></Route>
                        <Route path="/" element={<Home />} />
                        <Route path="/Home" element={<Home />} />
                        <Route path="/Welcome" element={<AuthenticateCode />} />
                        <Route path="/Authenticate" element={<AuthenticateBirthdate />} />
                        <Route path="/SurveySelect" element={<SurveySelect />} />
                        <Route path="/Survey" element={<SurveyOverview />} />
                        <Route path="/anonymous" element={<Anonymous />} />
                        {/* Unknown pages */}
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={4000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />

                </div>
            </BrowserRouter>
        </div >
    );
}

export default App;