import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Header } from '../../Components/Header';
import { Form, FormGroup } from "reactstrap";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField, Tooltip, Button } from '@mui/material';
import AuthenticationService from '../../Services/AuthenticationService';
import AuthenticationCodeModel from '../../Models/Authentication/AuthenticationCodeModel';
import SendAuthenticationCodeModel from '../../Models/Authentication/SendAuthenticationCodeModel';
import EDPBLogo from '../../Images/ClbEDPBLogo.png';
import LoadingScreen from '../LoadingScreen';
import { useTranslation } from 'react-i18next';
import SendAuthenticationCodeResult from '../../Models/Authentication/SendAuthenticationCodeResult';
import { LanguageSelector } from '../../Components/LanguageSelector';

export default function AuthenticateCode() {

    const { t } = useTranslation('welcome');

    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    const planningItemDetailId = queryParameters.get("id")
    const adminCode = queryParameters.get("adminCode")

    const authenticationService = useMemo(() => new AuthenticationService(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [codeRequested, setCodeRequested] = useState<boolean>(false);
    const [code, setCode] = useState<string>("");
    const [mobilePhone, setMobilePhone] = useState<string>();
    const [emailAddress, setEmailAddress] = useState<string>();
    const [loginData, setLoginData] = useState<SendAuthenticationCodeResult>();
    const [codeValidInMinutes, setCodeValidInMinutes] = useState<string>();
    const [adminLoginFailed, setAdminLoginFailed] = useState<boolean>(false);
    const [codeSendMessage, setCodeSendMessage] = useState<string>("");
    const [languageSelected, setLanguageSelected] = useState<boolean>(false);

    useEffect(() => {
        if (adminCode) {
            loginAdmin();
        } else {
            loginUser();
        }
    }, []);

    return (
        <>
            <Header
                description={t("Welkom")}
            />
            <img src={EDPBLogo} alt="CLB EDPB" className='p-3' />
            <Box p={2}>
                {getFormFields()}
            </Box>
        </>
    );

    function loginUser() {
        setLoading(true);

        let baseUrl = process.env.REACT_APP_ENV_URL;

        let model = {
            planningItemDetailId: planningItemDetailId,
            url: baseUrl
        } as SendAuthenticationCodeModel;

        if (planningItemDetailId) {
            authenticationService.LoadAuthenticationCode(model).then((result) => {

                if (result.success) {
                    //ok                    
                    //setCodeRequested(true);
                    setLoginData(result);
                    setCodeValidInMinutes(result.codeValidInMinutes);
                } else {
                    //nok
                    toast.error(t("Fout bij aanvragen van code."));
                }
            }).catch((error) => {
                toast.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function loginAdmin(): void {
        setLoading(true);
        setCodeRequested(true);

        let model = {
            planningItemDetailId: planningItemDetailId,
            code: adminCode
        } as AuthenticationCodeModel;

        authenticationService.checkAuthenticationCode(model).then((result) => {
            if (result.success) {
                toast.success(t("Login OK."));
                let state = {
                    state: {
                        planningItemDetailId: planningItemDetailId
                    }
                }
                navigate("/SurveySelect", state);
            } else {
                toast.error(result.message);

                //Backup, patient krijgt code om in te loggen manueel. 
                // toast.warning("Backup login methode actief.");
                loginUser();
                setAdminLoginFailed(true);
                setCodeRequested(false);

                //send2factorAuthCode();
            }
        }).catch((error) => {
            toast.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    function sendBySMS(event: React.FormEvent<HTMLFormElement>): void {
        //prevent form reload when clicked
        event.preventDefault()
        setLoading(true);

        let baseUrl = process.env.REACT_APP_ENV_URL

        let model = {
            planningItemDetailId: planningItemDetailId,
            url: baseUrl,
            mobilePhone: mobilePhone
        } as SendAuthenticationCodeModel;

        if (planningItemDetailId) {
            authenticationService.sendAuthenticationCodeBySMS(model).then((result) => {
                if (result.success) {
                    setCodeRequested(true);
                    let messageSend = t("Code verzonden naar");
                    setCodeSendMessage(messageSend + ' ' + result.mobilePhone);
                } else {
                    setCodeRequested(false);

                    toast.error(t("Fout bij aanvragen van code voor gsm nr {{1}}", { 1: result.mobilePhone }));
                }
            }).catch((error) => {
                toast.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function sendByEmail(event: React.FormEvent<HTMLFormElement>): void {
        //prevent form reload when clicked
        event.preventDefault()
        setLoading(true);

        let baseUrl = process.env.REACT_APP_ENV_URL

        let model = {
            planningItemDetailId: planningItemDetailId,
            url: baseUrl,
            emailAddress: emailAddress
        } as SendAuthenticationCodeModel;

        if (planningItemDetailId) {
            authenticationService.sendAuthenticationCodeByEmail(model).then((result) => {

                if (result.success) {
                    setCodeRequested(true);
                    let messageSend = t("Code verzonden naar");
                    setCodeSendMessage(messageSend + ' ' + result.emailAddress);

                } else {
                    setCodeRequested(false);

                    toast.error(t("Fout bij aanvragen van code voor email adres {{1}}", { 1: result.emailAddress }));
                }
            }).catch((error) => {
                toast.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function submitData(event: React.FormEvent<HTMLFormElement>): void {
        //prevent form reload when clicked
        event.preventDefault()
        setLoading(true);

        if (code !== undefined) {

            let model = {
                planningItemDetailId: planningItemDetailId,
                code: code
            } as AuthenticationCodeModel;

            authenticationService.checkAuthenticationCode(model).then((result) => {

                if (result.success) {
                    let state = {
                        state: {
                            planningItemDetailId: planningItemDetailId,
                            employeeName: loginData?.employeeName
                        }
                    }
                    navigate("/Authenticate", state);
                } else {
                    toast.error(result.message);
                }
            }).catch((error) => {
                toast.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function getFormFields() {
        if (loading) {
            return loadingScreen();
        }

        if (!languageSelected) {
            return (getLanguage())
        }

        if (codeRequested) {
            return (
                <Grid container spacing={2}>
                    {getAdminErrorMessage()}
                    <Grid item xs={0} md={4} />
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <h2>{t("Code")}</h2>
                                {codeSendMessage}
                                <Form onSubmit={submitData}>
                                    <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                                        <Tooltip title="Code">
                                            <TextField
                                                className='mt-3 mb-3'
                                                type="number"
                                                fullWidth
                                                margin="dense"
                                                id="code"
                                                label={t("Typ hier je code")}
                                                variant="outlined"
                                                required
                                                name="code"
                                                value={code}
                                                onChange={(event) => handleCodeChanged(event)}
                                                autoFocus={true} />
                                        </Tooltip>
                                    </FormGroup>
                                    <Button variant="contained" className='me-2' onClick={() => {
                                        setCodeSendMessage("");
                                        setCodeRequested(false);
                                        loginUser();
                                    }}>{t("Ga terug")}</Button>
                                    <Button variant="contained" type="submit">{t("Ga verder")}</Button>
                                </Form >
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={4} />
                </Grid >
            );
        }

        return (
            <Grid container spacing={2}>
                {getAdminErrorMessage()}
                <Grid item xs={0} md={4} />
                <Grid item xs={12} md={4}>
                    <Paper elevation={3}>
                        <Box p={2}>
                            {getEmployeeName()}
                            <h2>{t("Code aanvragen")}</h2>
                            <Form onSubmit={sendBySMS}>
                                <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                                    <TextField
                                        className='mt-3 mb-3'
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        id="mobilePhone"
                                        //label={loginData?.mobilePhone ?? "+324XXXXXXXX"}
                                        variant="outlined"
                                        name="mobilePhone"
                                        //value={loginData?.mobilePhone ?? "+324********"}
                                        required={loginData?.mobilePhone === undefined || loginData?.mobilePhone === null}
                                        placeholder={loginData?.mobilePhone ?? "+324********"}
                                        onChange={(event) => setMobilePhone(event.target.value)}
                                        autoFocus={true} />
                                </FormGroup>
                                <Button variant="contained" type="submit">{t("Verstuur code via SMS")}</Button>
                            </Form >
                            <Form onSubmit={sendByEmail}>
                                <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                                    <TextField
                                        className='mt-3 mb-3'
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        id="emailAddress"
                                        //label={loginData?.emailAddress ?? "xxx@xxx.be"}
                                        variant="outlined"
                                        name="emailAddress"
                                        //value={loginData?.emailAddress ?? "xxx@xxx.be"}
                                        required={loginData?.emailAddress === undefined || loginData?.emailAddress === null}
                                        placeholder={loginData?.emailAddress ?? "***@***.be"}
                                        onChange={(event) => setEmailAddress(event.target.value)}
                                        autoFocus={true} />
                                </FormGroup>
                                <Button variant="contained" type="submit">{t("Verstuur code via email")}</Button>
                            </Form >
                            <br />
                            <div>{t("U ontvangt een verificatiecode.")}</div>
                            <div>{t("Deze code blijft {{1}} minuten geldig.", { 1: codeValidInMinutes })}</div>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={0} md={4} />
            </Grid >
        );
    }

    function getLanguage() {

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={0} md={4} />
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <div>
                                    <h2>{t("Kies uw taal.")}</h2>
                                </div>
                                <div className='my-3'>
                                    <LanguageSelector useshortCodes={false} />
                                </div>
                                <div className='my-2'>
                                    <Button variant="contained" className='me-2' onClick={() => {
                                        setLanguageSelected(true);
                                    }}>{t("Doorgaan")}</Button>
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={4} />
                </Grid>
            </>
        )

    }

    function getAdminErrorMessage() {
        if (adminLoginFailed) {
            return (
                <>
                    <Grid item xs={0} md={4} />
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3}
                            style={{
                                backgroundColor: "rgb(213,166,189)",
                                color: "black",
                                fontWeight: "bold"
                            }}>
                            <Box p={2}>
                                <div>
                                    {t("Fout bij automatisch inloggen als admin.")}
                                </div>
                                <div>
                                    {t("Overgeschakeld naar manueel inloggen via patient.")}
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={4} />
                </>
            )
        }
    }

    function getEmployeeName() {
        if (loginData?.employeeName) {
            return (
                <>
                    {t("Werknemer:")} {loginData?.employeeName}
                </>
            )
        }
    }

    function handleCodeChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (event.target.name === "code") {
            setCode(event.target.value)
        }
    }

    function loadingScreen() {
        return (
            <LoadingScreen />
        )
    }
}