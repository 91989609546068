import { useState, useEffect, useMemo, useCallback, startTransition } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Header } from '../../Components/Header';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import SurveyService from '../../Services/SurveyService';
import SurveyTypeModel from "../../Models/Survey/SurveyTypeModel";
import EDPBLogo from '../../Images/ClbEDPBLogo.png';
import LoadingScreen from '../LoadingScreen';
import { useTranslation } from 'react-i18next';

const Anonymous = () => {

    const { t, i18n } = useTranslation('anonymous');

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const surveyService = useMemo(() => new SurveyService(), []);

    const [surveyTypeId, setSurveryTypeId] = useState<string>(searchParams.get("t") || "");
    const [accountId, setAccountId] = useState<string>(searchParams.get("a") || "");

    const [surveyId, setSurveyId] = useState<string | null>(localStorage.getItem("surveyid"));

    const [loading, setLoading] = useState<boolean>(false);
    const [surveyType, setSurveyType] = useState<SurveyTypeModel>();

    const loadSurveyType = useCallback(
        async () => {

            try {
                setLoading(true);
                if (surveyTypeId === "")
                    setSurveyType(undefined);

                const data = await surveyService.getSurveyType(surveyTypeId, null, surveyId, accountId, i18n.language);

                setSurveyType(data);
            } catch (error: any) {
                toast.error(error);
            } finally {
                setLoading(false);
            }
        },
        [surveyTypeId, accountId, surveyService]
    );

    useEffect(() => {
        async function loadData() {
            await loadSurveyType();
        }

        loadData();
    }, [loadSurveyType]);

    let body = null;
    if (loading) {
        body = <LoadingScreen />;
    }
    else if (surveyType && (surveyType.completed === true || surveyType.surveyId === null)) {
        body =
            <>
                <h2 className='float-center'>
                    {surveyType.name}
                </h2>
                <Button variant="contained" onClick={() => {
                    startTransition(() => {
                        navigate("/survey", {
                            state: {
                                surveyType: {
                                    surveyTypeId: surveyType.surveyTypeId,
                                    name: surveyType.name
                                },
                                accountId: accountId
                            }
                        });
                    });
                }} >{t("Starten")}</Button>
            </>;
    }
    else if (surveyType && surveyType.completed === false && surveyType.surveyId != null) {
        body =
            <>
                <h2 className='float-center'>
                    {surveyType.name}
                </h2>
                <Grid item xs={12}>
                    <Button className='m-3' variant="contained" onClick={() => {
                        startTransition(() => {
                            navigate("/survey", {
                                state: {
                                    surveyType: {
                                        surveyTypeId: surveyType.surveyTypeId,
                                        name: surveyType.name,
                                        surveyId: surveyType.surveyId
                                    },
                                    accountId: accountId
                                }
                            });
                        });
                    }}>{t("Verdergaan")}</Button>

                    <Button className='m-3' variant="contained" onClick={() => {
                        startTransition(() => {
                            navigate("/survey", {
                                state: {
                                    surveyType: {
                                        surveyTypeId: surveyType.surveyTypeId,
                                        name: surveyType.name
                                    },
                                    accountId: accountId
                                }
                            });
                        });
                    }}>{t("Starten")}</Button>
                </Grid>
            </>;
    }

    return (
        <>
            <Header
                description={t("Vragenlijst")}
            />
            <img src={EDPBLogo} alt="CLB EDPB" className='p-3' />
            <Box p={2}>
                {body}
            </Box>
        </>
    );
}

export default (Anonymous); 