import SetGeneralConditionsModel from '../Models/Survey/GeneralConditions/SetGeneralConditionsModel';
import GetGeneralConditionsResultModel from '../Models/Survey/GeneralConditions/GetGeneralConditionsResultModel';
import SetGeneralConditionsResultModel from '../Models/Survey/GeneralConditions/SetGeneralConditionsResultModel';
import ApiCalls from './ApiCalls';

export default class GeneralConditionsService {

    public async getGeneralConditions(surveyTypeId: string, language: string): Promise<GetGeneralConditionsResultModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + `/api/GeneralConditions/GetGeneralConditions/${language}`;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, surveyTypeId);
        return result;
    }

    public async setGeneralConditions(model: SetGeneralConditionsModel): Promise<SetGeneralConditionsResultModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/GeneralConditions/SetGeneralConditions/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }
}