import { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Box, Button, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import GetGeneralConditionsResultModel from "../../Models/Survey/GeneralConditions/GetGeneralConditionsResultModel";
import SetGeneralConditionsModel from "../../Models/Survey/GeneralConditions/SetGeneralConditionsModel";
import GeneralConditionsService from "../../Services/GeneralConditionsService";
import LoadingScreen from "../LoadingScreen";
import { useTranslation } from "react-i18next";
import React from "react";

type GeneralConditionsProps = {
  surveyTypeId: string;
  surveyId: string;
  callBack: Function;
};

export default function GeneralConditions(props: GeneralConditionsProps) {
  const { t, i18n } = useTranslation("survey");

  const [loading, setLoading] = useState<boolean>(false);
  const generalConditionsService = useMemo(
    () => new GeneralConditionsService(),
    []
  );
  const [generalConditions, setGeneralConditions] =
    useState<GetGeneralConditionsResultModel>();

  useEffect(() => {
    loadGeneralConditions();
  }, []);

  useEffect(() => {
    loadGeneralConditions();
  }, [i18n.language]);

  function loadGeneralConditions() {
    setLoading(true);

    if (props.surveyTypeId) {
      generalConditionsService
        .getGeneralConditions(props.surveyTypeId, i18n.language)
        .then((result) => {
          if (result.generalConditions === null) {
            toast.error(
              t("Geen algemene voorwaarden aangetroffen bij deze vragenlijst.")
            );
          } else {
            setGeneralConditions(result);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(t("Fout bij ophalen van algemene voorwaarden."));
        })
        .finally(() => {});
    }
  }

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={0} md={2} />
        <Grid item xs={12} md={8}>
          <Paper elevation={3}>
            <h2>{t("Algemene voorwaarden.")}</h2>
            <div>{getFormFields()}</div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            className="m-3"
            color="error"
            variant="contained"
            onClick={() => submitData(false)}
          >
            {t("Weigeren")}
          </Button>
          <Button
            className="m-3"
            color="success"
            variant="contained"
            onClick={() => submitData(true)}
          >
            {t("Accepteren")}
          </Button>
        </Grid>
        <Grid item xs={0} md={2} />
      </Grid>
    </Box>
  );

  function submitData(accepted: Boolean) {
    let model = {
      surveyId: props.surveyId,
      accepted: accepted,
    } as SetGeneralConditionsModel;

    generalConditionsService.setGeneralConditions(model).then((result) => {
      props.callBack(result);
    });
  }

  function getFormFields() {
    if (loading) return loadingScreen();
    if (generalConditions?.generalConditions != undefined)
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: generalConditions?.generalConditions,
          }}
        ></div>
      );
  }

  function loadingScreen() {
    return <LoadingScreen />;
  }
}
