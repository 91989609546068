import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Modal, Typography } from '@mui/material';
import LoadingScreen from '../LoadingScreen';
import SurveyTypeModel from '../../Models/Survey/SurveyTypeModel';
import SurveyService from '../../Services/SurveyService';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SurveyInfoModel from '../../Models/Survey/SurveyInfoModel';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';

type Props = {
    surveyType: SurveyTypeModel
};

export default function SurveyInfo(props: Props) {

    const { t, i18n } = useTranslation('survey');

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [loading, setLoading] = useState<boolean>(false);
    const surveyService = useMemo(() => new SurveyService(), []);
    const [surveyInfo, setSurveyInfo] = useState<SurveyInfoModel>();

    useEffect(() => {
        loadSurveyInfo();
    }, []);

    useEffect(() => {
        loadSurveyInfo();
    }, [i18n.language]);

    function loadSurveyInfo() {
        setLoading(true);
        if (props.surveyType.surveyTypeId) {
            surveyService.getInfo(props.surveyType.surveyTypeId, i18n.language).then((result) => {

                if (result === null) {
                    toast.error(t('Geen informatie gevonden voor deze vragenlijst.'));
                } else {
                    setSurveyInfo(result);
                    setLoading(false);
                }
            }).catch((error) => {
                setLoading(false);
                toast.error(t('Fout bij ophalen van info voor vragenlijst voorwaarden.'));
            }).finally(() => {
            });
        }
    }

    return (
        <>
            {getFormFields()}
        </>
    );

    function getFormFields() {
        if (loading)
            return loadingScreen();

        return (
            <div>
                <InfoOutlinedIcon onClick={handleOpen} />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {surveyInfo?.name}
                        </Typography>
                        <Typography id="modal-modal-description" >
                            <AccessTimeIcon /> {surveyInfo?.expectedTime} {t("minuten.")}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {surveyInfo?.info}
                        </Typography>

                        <Button
                            className="m-2 float-end"
                            variant="contained"
                            onClick={handleClose}
                        >{t("Sluiten")}
                        </Button>
                    </Box>
                </Modal>
            </div>
        );
    }

    function loadingScreen() {
        return (
            <LoadingScreen />
        )
    }
}