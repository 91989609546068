import { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Box, Button, Grid, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import SurveyService from "../../Services/SurveyService";
import QuestionModel from "../../Models/Survey/Questions/QuestionModel";
import LoadQuestionModel from "../../Models/Survey/Questions/LoadQuestionModel";
import PossibleAnswerModel from "../../Models/Survey/Questions/PossibleAnswerModel";
import LoadingScreen from "../LoadingScreen";
import SurveyModel from "../../Models/Survey/SurveyModel";
import FinishSurveyModel from "../../Models/Survey/FinishSurveyModel";
import { useTranslation } from "react-i18next";
import { EndingText } from "../../Components/EndingText";

type Props = {
  survey: SurveyModel;
  surveyCompleted: Function;
  surveyTypeId: string;
};

export default function SurveyDetail(props: Props) {
  const { t, i18n } = useTranslation("survey");

  const surveyService = useMemo(() => new SurveyService(), []);
  const [loading, setLoading] = useState<boolean>(true);
  const [questionModel, setQuestionModel] = useState<QuestionModel>();
  const [isFirstQuestion, setIsFirstQuestion] = useState<boolean>(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(
    props.survey.nextQuestionId
  );

  useEffect(() => {
    loadQuestion();
  }, []);

  useEffect(() => {
    loadQuestion();
  }, [i18n.language]);

  return (
    <Box p={2} sx={{ height: "100%", width: "100%" }}>
      {getFormFields()}
      {backButton()}
    </Box>
  );

  function loadQuestion() {
    setLoading(true);
    let model = {
      surveyId: props.survey.id,
      questionId: currentQuestionId,
    } as LoadQuestionModel;

    surveyService
      .loadQuestion(model, i18n.language)
      .then((result) => {
        if (result === null) {
          toast.error(t("Vraag niet gevonden."));
        } else {
          //set Progress bar
          setQuestionModel(result);
          setIsFirstQuestion(result.thisIsTheFirstQuestion);
        }
      })
      .catch((error) => {
        toast.error(t("Fout bij ophalen van vraag."));
      })
      .finally(() => {});

    setLoading(false);
  }

  function clickAnswer(answer: PossibleAnswerModel) {
    if (questionModel) {
      setLoading(true);

      surveyService
        .saveAnswer(
          {
            surveyId: props.survey.id,
            nextQuestionId: answer.nextQuestionId,
            possibleAnswerId: answer.id,
            questionId: questionModel.id,
            answer: answer.answer,
          },
          i18n.language
        )
        .then((result) => {
          if (result) {
            if (result.success) {
              //do stuff with question
              if (result.nextQuestionModel !== null) {
                //set Progress bar
                setQuestionModel(result.nextQuestionModel);
                setIsFirstQuestion(
                  result.nextQuestionModel.thisIsTheFirstQuestion
                );
                setCurrentQuestionId(result.nextQuestionModel.id);
              }
            } else {
              toast.error(t("Fout bij opslaan van vraag."));
            }
          }
          setLoading(false);
        });
    }
  }

  function getFormFields() {
    if (loading) return loadingScreen();

    if (questionModel?.allQuestionsAreAnswered) return surveyCompletedScreen();

    if (questionModel !== undefined) return question();
  }

  function question() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={0} md={2} />
        <Grid item xs={12} md={8}>
          {questionModel?.category ?? "" !== "" ? (
            <h3>{questionModel?.category}</h3>
          ) : (
            <></>
          )}
          <Paper
            elevation={3}
            style={{
              backgroundColor: "rgb(213,166,189)",
              color: "black",
              fontWeight: "bold",
            }}
          >
            <div className="p-2 text-start">{questionModel?.question}</div>
          </Paper>
          <Box p={2}>
            <Stack
              className="answers-stack"
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              sx={{
                justifyContent: "flex-end",
              }}
            >
              {answers()}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={0} md={2} />
      </Grid>
    );
  }

  function answers() {
    if (
      questionModel !== undefined &&
      questionModel.possibleAnswers?.length > 0
    ) {
      //const reverseOrder = [...questionModel.possibleAnswers].reverse();

      const mapped = questionModel.possibleAnswers.map(
        (model: PossibleAnswerModel) => {
          return (
            <Button
              className="m-2"
              variant={model.selected === true ? "contained" : "outlined"}
              key={model.id}
              style={{
                textTransform: "none",
                maxWidth: "100%",
              }}
              onClick={() => clickAnswer(model)}
            >
              {model.translatedAnswer ?? model.answer}
            </Button>
          );
        }
      );
      return mapped;
    }
  }

  function surveyCompletedScreen() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <Paper
              elevation={3}
              style={{
                backgroundColor: "rgb(213,166,189)",
                color: "black",
                fontWeight: "bold",
              }}
            >
              <div className="p-2 text-start">
                {/* {t("U hebt alle vragen beantwoord.")} */}
                <EndingText surveyTypeId={props.surveyTypeId} />
              </div>
            </Paper>

            <Grid item>
              <Box p={0}>
                <Button
                  className="mt-3 float-end"
                  variant="outlined"
                  key="123"
                  style={{
                    textTransform: "none",
                    maxWidth: "100%",
                  }}
                  onClick={() => finishSurvey(true)}
                >
                  {t("Bevestigen en doorsturen")}
                </Button>
              </Box>
            </Grid>
            <br />
          </Grid>
          <Grid item xs={0} md={2} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <div className="mt-5">
            {t(
              "Na bevestiging wordt de lijst afgesloten en kan je deze niet meer aanpassen."
            )}
          </div>
        </Grid>
      </>
    );
  }

  function finishSurvey(answer: boolean) {
    let model = {
      completed: answer,
      surveyId: props.survey.id,
    } as FinishSurveyModel;

    surveyService.finishSurvey(model).then((result) => {
      if (result) {
        toast.success(t("Vragenlijst afgesloten."));
        props.surveyCompleted();
      } else {
        toast.warning(t("Vragenlijst niet afgesloten."));
        //terug naar vorige vraag gaan via knop.
      }
    });
  }

  function backButton() {
    if (!loading) {
      if (isFirstQuestion !== true) {
        return (
          <Grid container spacing={2} p={3} className="fixed-bottom">
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={10}>
              <Button
                className="float-start"
                variant="contained"
                key="456"
                style={{
                  textTransform: "none",
                  maxWidth: "100%",
                }}
                onClick={() => previousQuestion()}
              >
                {t("Terug naar vorige vraag")}
              </Button>
            </Grid>
          </Grid>
        );
      }
    }
  }

  function previousQuestion() {
    setLoading(true);
    let model = {
      surveyId: props.survey.id,
      questionId: questionModel?.id,
    } as LoadQuestionModel;

    surveyService
      .previousQuestion(model, i18n.language)
      .then((result) => {
        if (result === null) {
          toast.error(t("Vraag niet gevonden."));
        } else {
          if (result.thisIsTheFirstQuestion) {
            toast.info(t("Dit is de eerste vraag."));
            setIsFirstQuestion(result.thisIsTheFirstQuestion);
          } else {
            setIsFirstQuestion(result.thisIsTheFirstQuestion);
            //set Progress bar
            setQuestionModel(result);
            setCurrentQuestionId(result.id);
          }
        }
      })
      .catch((error) => {
        toast.error(t("Fout bij ophalen van vraag."));
      })
      .finally(() => {});

    setLoading(false);
  }

  function loadingScreen() {
    return <LoadingScreen />;
  }
}
