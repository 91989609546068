import React, { useState, useEffect, useContext, ReactNode } from "react";

interface AuthProviderProps {
    children?: ReactNode;
}

interface AuthContextProvider {
    isAuthenticated: boolean;
    user: Claim[];
    isLoading: boolean;
    login: Function;
    logout: Function;
    isActive: boolean;
}

interface Claim {
    type: string;
    value: string;
}

export const AuthContext = React.createContext<AuthContextProvider>({
    isAuthenticated: false,
    user: [],
    isLoading: false,
    login: () => { },
    logout: () => { },
    isActive: false
});
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }: AuthProviderProps) => {
    const authUrl = process.env.REACT_APP_AUTH_URL;

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<Claim[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState((authUrl ?? "").length > 0);

    const getUser = async () => {
        const response = await fetch(`${authUrl}/auth/getUser`, {
            credentials: "include"
        });
        const json = await response.json();

        setIsAuthenticated(json.isAuthenticated);
        setIsLoading(false);
        if (json.isAuthenticated) setUser(json.claims);
    }

    useEffect(() => {
        getUser();
    }, []);

    const login = () => {
        window.location.href = `${authUrl}/auth/login?returnurl=${encodeURI(process.env.REACT_APP_ENV_URL ?? "")}`;
    }

    const logout = () => {
        window.location.href = `${authUrl}/auth/logout?returnurl=${encodeURI(process.env.REACT_APP_ENV_URL ?? "")}`;
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                isLoading,
                login,
                logout,
                isActive
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};