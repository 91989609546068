import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SurveyService from "../Services/SurveyService";

interface Props {
    surveyTypeId: string;
}

export function EndingText(props: Props) {

    const { t, i18n } = useTranslation('survey');

    const surveyService = useMemo(() => new SurveyService(), []);

    const [endingText, setEndingText] = useState<string>("");

    function loadEndingText() {

        const defaultEndingText = t("U hebt alle vragen beantwoord.");
        setEndingText(defaultEndingText);

        surveyService.getEndingText(props.surveyTypeId, i18n.language).then((result) => {
            setEndingText(result.endingText);
        }).catch((error: any) => {
            //toast.error(t('Fout bij ophalen van vraag.'));
        }).finally(() => {
        });

    }

    useEffect(() => {
        loadEndingText();
    }, []);

    return (
        <>{endingText}</>
    );
}
