import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Header } from '../Components/Header';
import { Grid, Paper } from '@mui/material';
import LoadingScreen from './LoadingScreen';
import { useTranslation } from 'react-i18next';

export default function NotFound() {

    const { t } = useTranslation('notfound');

    const navigate = useNavigate();
    const [redirect, setRedirect] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setRedirect(true);
        }, 2000)
    }, []);

    return (
        <>
            {page()}
        </>
    );

    function page() {
        if (redirect) {
            navigate("/Home");
        } else {
            return (
                <>
                    <Header
                        description="Fout"
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <h1>
                                    {t("Pagina niet gevonden")}
                                </h1>
                                <LoadingScreen />
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }
}