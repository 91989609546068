import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Header } from '../../Components/Header';
import { Table } from "reactstrap";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SurveyService from '../../Services/SurveyService';
import SurveyTypeModel from "../../Models/Survey/SurveyTypeModel";
import EDPBLogo from '../../Images/ClbEDPBLogo.png';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import LoadingScreen from '../LoadingScreen';
import SurveyInfo from './SurveyInfo';
import { useTranslation } from 'react-i18next';

export default function SurveySelect() {

    const { t, i18n } = useTranslation('surveyselect');

    const navigate = useNavigate();
    const location = useLocation();
    const states = location.state as any;
    const planningItemDetailId = states.planningItemDetailId as string;

    const surveyService = useMemo(() => new SurveyService(), []);

    const [loading, setLoading] = useState<boolean>(false);
    const [surveysTypes, setSurveysTypes] = useState<SurveyTypeModel[]>();

    useEffect(() => {
        loadSurveys();
    }, []);

    useEffect(() => {
        loadSurveys();
    }, [i18n.language]);

    return (
        <>
            <Header
                description={t("Selecteer Vragenlijst")}
            />
            <img src={EDPBLogo} alt="CLB EDPB" className='p-3' />
            <Box p={2}>
                {getFormFields()}
            </Box>
        </>
    );

    function loadSurveys() {
        setLoading(true);
        if (planningItemDetailId) {
            surveyService.getSurveyTypes(planningItemDetailId, i18n.language).then((result) => {
                if (result) {
                    if (result.surveyTypes != null && result.surveyTypes.length > 0) {
                        //ok
                        setSurveysTypes(result.surveyTypes);
                        setLoading(false);
                    } else {
                        //nok
                        toast.error(result.message);
                        setLoading(false);
                    }
                } else {
                    //niks terug
                    toast.error(t("Fout bij ophalen van de vragenlijsten."));
                    setLoading(false);
                }
            }).catch((error) => {
                toast.error(error);
                setLoading(false);
            }).finally(() => {
            });
        }
    }

    function getFormFields() {
        if (loading) {
            return loadingScreen();
        }

        if (surveysTypes !== undefined) {
            var isAllTrue = surveysTypes.every(function (surveyTypeModel) {
                return surveyTypeModel.completed === true;
            });
            if (isAllTrue) {
                return (
                    <>
                        <div>
                            {t("Bedankt voor het invullen van onze vragen.")}
                        </div>
                        <div className='mt-3'>
                            {t("U kan nu de pagina afsluiten.")}
                        </div>
                    </>
                )
            }
        }

        return (
            tableSurveys()
        );
    }

    function tableSurveys() {
        return (

            <Grid container spacing={2}>
                <Grid item xs={0} md={4} />
                <Grid item xs={12} md={4}>
                    <Paper elevation={3}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: "bold" }} colSpan={3}>{t("Selecteer vragenlijst")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {surveysTypes !== undefined && surveysTypes?.length > 0 ?
                                        surveysTypes.map((surveyType: SurveyTypeModel) => {

                                            let onclick: any = (surveyType.completed === true) ? () => selectSurvey(null) : () => selectSurvey(surveyType);

                                            let style: any = (surveyType.completed === true) ?
                                                { backgroundColor: "rgb(154,206,158)", color: "black" } :
                                                { backgroundColor: "rgb(252,252,252)", color: "black" };

                                            function getIcon(surveyType: SurveyTypeModel): React.ReactNode {
                                                if (surveyType.completed === true) {
                                                    return (<CheckBoxIcon />)
                                                } else {
                                                    return (<CheckBoxOutlineBlankIcon />)
                                                }
                                            }
                                            return (
                                                <TableRow
                                                    key={surveyType.surveyTypeId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                >
                                                    <TableCell
                                                        onClick={onclick}
                                                        align="center"
                                                        width="10%"
                                                        style={style}
                                                    >{getIcon(surveyType)}</TableCell>
                                                    <TableCell
                                                        onClick={onclick}
                                                        align="left"
                                                        width="80%"
                                                        component="th"
                                                        scope="row"
                                                        style={style}
                                                    >{surveyType.name}</TableCell>
                                                    <TableCell
                                                        align="center"
                                                        width="10%"
                                                        style={style}
                                                    ><SurveyInfo
                                                            surveyType={surveyType}
                                                        /></TableCell>
                                                </TableRow>
                                            );
                                        }) : <TableRow>
                                            <TableCell align="center" width="100%">{t("Geen vragenlijsten gevonden.")}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={0} md={4} />
            </Grid>

        )
    }

    function selectSurvey(surveyType: SurveyTypeModel | null) {
        if (surveyType !== null) {
            let state = {
                state: {
                    surveyType: surveyType,
                    planningItemDetailId: planningItemDetailId
                }
            }

            navigate("/Survey", state);
        } else {
            toast.warn(t("Vragenlijst is afgesloten."))
        }
    }

    function loadingScreen() {
        return (
            <LoadingScreen />
        )
    }
}