import { toast } from "react-toastify";

export default class ApiCalls {

    public async Get(url: string) {
        return this.Call("GET", url, null);
    }

    public async Post(url: string, model: any) {
        return this.Call("POST", url, model);
    }

    public async Put_DO_NOT_USE(url: string, model: any) {
        return this.Call("PUT", url, model);
    }

    private async Call(method: string, url: string, model: any | null) {

        let request;

        if (model === null) {
            request = {
                method: method,
                headers: new Headers({
                    'Content-Type': 'application/json; charset=UTF-8'
                })
            }
        } else {
            request = {
                method: method,
                body: JSON.stringify(model),
                headers: new Headers({
                    'Content-Type': 'application/json; charset=UTF-8'
                })
            }
        }

        return fetch(url, request).then(function (response) {
            let result = response;
            return result.json();
        }).catch(error => {
            // console.log("error");
            // console.log(error);
            if (error.message === "Failed to fetch") {
                toast.error("CLB Service is niet bereikbaar.");
            } else {
                toast.error(error.message);
            }
        });
    }
}