import * as React from 'react';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Header } from '../../Components/Header';
import { Form, FormGroup } from "reactstrap";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField, Button } from '@mui/material';
import EDPBLogo from '../../Images/ClbEDPBLogo.png';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useLocation } from 'react-router-dom';
import AuthenticationService from '../../Services/AuthenticationService';
import AuthenticationBirthdayModel from '../../Models/Authentication/AuthenticationBirthdayModel';
import LoadingScreen from '../LoadingScreen';
import { useTranslation } from 'react-i18next';

export default function AuthenticateBirthdate() {

    const { t } = useTranslation('authenticate');

    const navigate = useNavigate();
    const location = useLocation();

    const states = location.state as any;
    const planningItemDetailId = states?.planningItemDetailId as string;
    const employeeName = states?.employeeName as string;

    const authenticationService = useMemo(() => new AuthenticationService(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [birthDate, setBirthDate] = useState<Date>(new Date("2000-01-01"));

    return (
        <>
            <Header
                description={t("Welkom")}
            />
            <img src={EDPBLogo} alt="CLB EDPB" className='p-3' />
            <Box p={2}>
                {getFormFields()}
            </Box>
        </>
    );


    function submitData(event: React.FormEvent<HTMLFormElement>): void {
        //prevent form reload when clicked
        event.preventDefault()
        setLoading(true);

        let model = {
            planningItemDetailId: planningItemDetailId,
            birthdate: birthDate
        } as AuthenticationBirthdayModel;

        authenticationService.confirmBirthDate(model).then((result) => {
            if (result !== undefined) {
                if (result === true) {
                    //Success
                    toast.success(t("Geboortedatum OK."));
                    let state = {
                        state: {
                            planningItemDetailId: planningItemDetailId
                        }
                    }
                    navigate("/SurveySelect", state);
                } else {
                    //Failed                   
                    toast.error(t("Geboortedatum komt niet overeen."));
                    setLoading(false);
                }
            } else {
                toast.error(t("Geboortedatum komt niet overeen."));
                setLoading(false);
            }
        }).catch((error) => {
            toast.error(error);
            setLoading(false);
        }).finally(() => {
        });

    }

    function getFormFields() {
        if (loading) {
            return loadingScreen();
        }

        return (
            <Form onSubmit={submitData}>
                <Grid container spacing={2}>
                    <Grid item xs={0} md={4} />
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                {getEmployeeName()}
                                <h2>{t("Bevestig met je geboortedatum")}</h2>
                                <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className='mt-3 mb-3'
                                            label={t("Geboortedatum")}
                                            onChange={dateChanged}
                                            value={birthDate}
                                            inputFormat='DD/MM/YYYY'
                                            renderInput={(props) =>
                                                <TextField {...props}
                                                    autoFocus={true}
                                                    onFocus={e => e.target.select()}
                                                />
                                            }
                                        />
                                    </LocalizationProvider>
                                </FormGroup>
                                <div>
                                    {t("Om zeker te zijn dat jij het bent vragen we je hier om je geboortedatum te bevestigen.")}
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={4} />
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit">{t("Doorgaan")}</Button>
                    </Grid>
                </Grid >
            </Form >
        );
    }

    function dateChanged(value: unknown, keyboardInputValue?: string | undefined): void {
        setBirthDate(value as Date);
    }

    function loadingScreen() {
        return (
            <LoadingScreen />
        )
    }

    function getEmployeeName() {
        if (employeeName) {
            return (
                <>
                    {t("Werknemer:")} {employeeName}
                </>
            )
        }
    }
}