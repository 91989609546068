import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

//styling
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Translations
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend'
import { AuthProvider } from './AuthContext';
import React from 'react';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

let reloadResourcesTimeoutId: number | null = null;

i18next
    .use(HttpBackend)
    .init<HttpBackendOptions>({
        backend: {
            loadPath: (lng, namespace) => {
                return process.env.REACT_APP_API_URL + '/api/translations/{{lng}}/{{ns}}';
            },
            addPath: (lng, namespace) => {
                if (reloadResourcesTimeoutId !== null) {
                    window.clearTimeout(reloadResourcesTimeoutId);
                    reloadResourcesTimeoutId = null;
                }

                reloadResourcesTimeoutId = window.setTimeout(() => {
                    reloadResourcesTimeoutId = null;

                    window.location.reload();
                }, 1000);

                return process.env.REACT_APP_API_URL + '/api/translations/add/{{lng}}/{{ns}}';
            }
        },
        interpolation: { escapeValue: false },  // React already does escaping
        lng: window.sessionStorage.getItem("CLBEDPBSURVAYLNG") ?? "nl",
        fallbackLng: false,
        saveMissing: true
    });

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
