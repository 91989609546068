import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";

export function LanguageSelector(props: { useshortCodes: boolean }) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(
    window.sessionStorage.getItem("CLBEDPBSURVAYLNG") ?? "nl"
  );

  const languageCodes = {
    AR: "العربية",
    BG: "български",
    CS: "čeština",
    DA: "dansk",
    DE: "Deutsch",
    EL: "ελληνικά",
    EN: "English",
    ES: "español",
    ET: "eesti",
    FI: "suomi",
    FR: "français",
    HR: "hrvatski",
    HU: "magyar",
    IT: "italiano",
    LT: "lietuvių",
    LV: "latviešu",
    MT: "Malti",
    NL: "Nederlands",
    PL: "polski",
    PT: "português",
    RO: "română",
    SK: "slovenčina",
    SL: "slovenščina",
    SV: "svenska",
  };

  useEffect(() => {
    window.sessionStorage.setItem("CLBEDPBSURVAYLNG", language);
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return getDropdown();

  function getDropdown() {
    if (props.useshortCodes) {
      return dropdownShortCode();
    } else {
      return dropdownFullCountryName();
    }
  }

  function dropdownShortCode() {
    return (
      <Select
        size="small"
        labelId="language-selector"
        id="language"
        value={i18n.language}
        onChange={(event) => setLanguage(event.target.value)}
        variant="outlined"
        sx={{
          fontSize: "13px",
          borderRadius: "15px",
          color: "#212529",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#212529",
          },
        }}
      >
        {Object.entries(languageCodes).map(([code, name]) => (
          <MenuItem value={code.toLowerCase()} key={code.toLowerCase()}>
            {code}
          </MenuItem>
        ))}
      </Select>
    );
  }

  function dropdownFullCountryName() {
    return (
      <Select
        size="small"
        labelId="language-selector"
        id="language"
        value={i18n.language}
        onChange={(event) => setLanguage(event.target.value)}
        variant="outlined"
        sx={{
          fontSize: "20px",
          borderRadius: "10px",
          color: "black",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
        }}
      >
        {Object.entries(languageCodes).map(([code, languageName]) => (
          <MenuItem value={code.toLowerCase()}>{languageName}</MenuItem>
        ))}
      </Select>
    );
  }
}
